import React from "react"

/* Import Local Styles */
import "../icon.css"

const DownArrowSvg = () => (
  <svg
    className="icon no-fill"
    viewBox="0 0 77 96"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.0737 0.343682C31.0035 11.5829 32.3714 24.0384 33.2507 37.7346C34.0813 50.9686 34.9118 68.3382 35.7423 89.8435C35.669 89.7462 35.6202 89.6489 35.5469 89.5516C34.6675 88.2622 33.495 86.6323 31.9806 84.6861C31.9317 84.6375 31.9073 84.5645 31.8584 84.5158C28.0234 77.9475 24.5792 71.8657 21.5746 66.2218C18.6678 60.7968 15.5656 54.7393 12.3168 48.0493C12.1946 47.8061 12.0236 47.6114 11.8038 47.4655C10.4603 46.0545 6.57642 42.9406 2.00856 47.4412C1.95971 47.4898 1.88642 47.5385 1.83757 47.6114C1.78872 47.6844 1.71543 47.7331 1.66658 47.8061C-1.46009 51.1389 0.591796 55.5421 1.56888 57.1964C1.64216 57.464 1.78871 57.6829 1.98413 57.8775C7.40693 62.8889 12.3168 67.8517 16.7137 72.7415C21.2327 77.7772 26.46 83.932 32.3958 91.1572C32.4202 91.1815 32.4447 91.2301 32.4691 91.2545C33.1286 92.2275 33.9591 93.3466 34.9606 94.6359C35.8644 95.8036 39.6018 95.8036 40.5056 94.6359C41.5071 93.3466 42.3376 92.2275 42.9971 91.2545C43.0216 91.2301 43.046 91.1815 43.0704 91.1572C49.0062 83.932 54.2336 77.8015 58.7526 72.7415C63.1495 67.8274 68.0593 62.8889 73.4821 57.8775C73.5798 57.8046 73.6531 57.7072 73.702 57.6099L73.7264 57.6343C73.7264 57.6343 80.2484 52.8661 73.9706 47.0276C69.7692 43.1109 63.8579 47.3438 63.8579 47.3438L63.8579 47.3682C63.5403 47.5141 63.2716 47.7574 63.125 48.098C59.8518 54.788 56.774 60.8455 53.8672 66.2704C50.8382 71.9143 47.4184 77.9961 43.5834 84.5645C43.559 84.6131 43.5101 84.6861 43.4613 84.7348C41.9468 86.6809 40.7499 88.2865 39.8949 89.6002C39.7728 89.7705 39.6506 89.9651 39.5529 90.1354C40.5056 67.7787 41.4094 50.1658 42.2888 37.2724C43.217 24.0141 44.6826 11.7045 46.7101 0.368004C46.7589 -0.118545 28.976 -0.118538 29.0737 0.343682Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default DownArrowSvg
