import React, { useContext, useState } from "react"

/* Import Local COntext */
import CarouselContext from "../../context/_carousel"

import "./scrollNav.css"

const ScrollNav = ({ children }) => {
  const [, { nextSlide, previousSlide }] = useContext(CarouselContext)
  const [debounce, setDebounce] = useState(false)

  const timer = () => {
    setTimeout(() => {
      setDebounce(false)
    }, 1000)
  }

  const changeNav = e => {
    let direction = e.deltaY
    if (debounce) {
      return
    } else if (direction >= 1) {
      setDebounce(true)
      timer()
      nextSlide()
      return
    } else if (direction <= -1) {
      setDebounce(true)
      timer()
      previousSlide()
      return
    }
  }

  return <div onWheel={changeNav}>{children}</div>
}

export default ScrollNav
