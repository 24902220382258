import React, { useEffect } from "react"
import { graphql } from "gatsby"

/* Import Local Providers */
import CarouselProvider from "./providers/_carousel"

/* Import Global Components */
import Page from "~components/page"

/* Import Local Components */
import PreviousWorkButton from "./components/previousWorkButton/_previousWorkButton"
import Carousel from "./components/carousel/_carousel"
import CarouselItem from "./components/carouselItem/_carouselItem"
import Work from "./components/work/_work"
import NextWorkButton from "./components/nextWorkButton/_nextWorkButton"
import ScrollNav from "./components/scrollNav/_scrollNav"

/* Import Local Styles */
import "./works.css"

const Works = ({
  data: {
    sanityProjectsSettings: { projects: works },
  },
}) => {
  useEffect(() => {
    // lock body
    if (document) {
      document.body.style.overflow = "hidden"
      document.body.style.height = "100%"
    }
    // unlock body
    return () => {
      if (document) {
        document.body.removeAttribute("style")
      }
    }
  }, [])

  return (
    <Page className="works">
      <CarouselProvider>
        <ScrollNav>
          <PreviousWorkButton />
          <Carousel>
            {works.map((work, index) => (
              <CarouselItem key={index}>
                <Work project={work} />
              </CarouselItem>
            ))}
          </Carousel>
          <NextWorkButton />
        </ScrollNav>
      </CarouselProvider>
    </Page>
  )
}

export default Works

export const query = graphql`
  query WorksPageQuery {
    sanityProjectsSettings(_id: { eq: "projectsSettings" }) {
      projects {
        _id
        title
        categories
        heroImage {
          asset {
            url
            extension
          }
        }
      }
    }
  }
`
