import React, { useContext } from "react"

/* Import Local COntext */
import CarouselContext from "../../context/_carousel"

/* Import Global Components */
import UpArrowSvg from "~components/icon/normal/upArrow"

/* Import Local Styles */
import "./previous-work-button.css"

const PreviousWorkButton = () => {
  const [, { previousSlide }] = useContext(CarouselContext)
  return (
    <div className="previous-work-button">
      <button onClick={previousSlide}>
        <UpArrowSvg />
      </button>
    </div>
  )
}

export default PreviousWorkButton
