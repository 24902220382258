import React from "react"

/* Import Local Styles */
import "../icon.css"

const UpArrowSvg = () => (
  <svg
    viewBox="0 0 77 96"
    className="icon no-fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.9263 95.168C45.9965 83.9289 44.6286 71.4733 43.7493 57.7771C42.9187 44.5431 42.0882 27.1735 41.2577 5.66824C41.331 5.76555 41.3798 5.86285 41.4531 5.96016C42.3325 7.24951 43.505 8.87943 45.0194 10.8256C45.0683 10.8743 45.0927 10.9472 45.1416 10.9959C48.9766 17.5642 52.4208 23.646 55.4254 29.29C58.3322 34.7149 61.4344 40.7724 64.6832 47.4624C64.8054 47.7057 64.9764 47.9003 65.1962 48.0462C66.5397 49.4572 70.4236 52.5711 74.9914 48.0706C75.0403 48.0219 75.1136 47.9733 75.1624 47.9003C75.2113 47.8273 75.2846 47.7786 75.3334 47.7057C78.4601 44.3728 76.4082 39.9696 75.4311 38.3154C75.3578 38.0478 75.2113 37.8288 75.0159 37.6342C69.5931 32.6228 64.6832 27.66 60.2864 22.7703C55.7673 17.7345 50.54 11.5797 44.6042 4.35457C44.5798 4.33024 44.5553 4.28159 44.5309 4.25726C43.8714 3.28417 43.0409 2.16512 42.0394 0.875778C41.1356 -0.291928 37.3982 -0.291928 36.4944 0.875778C35.4929 2.16512 34.6624 3.28417 34.0029 4.25726C33.9784 4.28159 33.954 4.33024 33.9296 4.35457C27.9938 11.5797 22.7664 17.7102 18.2474 22.7703C13.8505 27.6844 8.9407 32.6228 3.5179 37.6342C3.42019 37.7072 3.3469 37.8045 3.29805 37.9018L3.27363 37.8775C3.27363 37.8775 -3.24839 42.6456 3.02936 48.4841C7.23081 52.4008 13.1421 48.1679 13.1421 48.1679L13.1421 48.1435C13.4597 47.9976 13.7284 47.7543 13.875 47.4137C17.1482 40.7237 20.226 34.6663 23.1328 29.2413C26.1618 23.5974 29.5816 17.5156 33.4166 10.9472C33.441 10.8986 33.4899 10.8256 33.5387 10.777C35.0532 8.83078 36.2501 7.22518 37.1051 5.91151C37.2272 5.74122 37.3494 5.5466 37.4471 5.37631C36.4944 27.733 35.5906 45.3459 34.7112 58.2393C33.783 71.4977 32.3174 83.8072 30.2899 95.1437C30.2411 95.6303 48.024 95.6303 47.9263 95.168Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default UpArrowSvg
