import React from "react"
import { Link } from "gatsby"
import slug from "slug"
import classnames from "classnames"

import "./work.css"

const Work = ({
  active,
  project: {
    title,
    categories,
    heroImage: {
      asset: { url, extension },
    },
  },
  alt = "alt text",
}) => {
  return (
    <div className={classnames("work", { active: active })}>
      <Link to={`/work/${slug(title)}`} className="link">
        <img
          className="image"
          src={
            extension === "gif"
              ? `${url}`
              : `${url}?max-w=1440&fit=max&w=1440&fm=png`
          }
          alt={alt}
        />
        <div className="text-container">
          <p className="title">{title}</p>
          <p className="categories">
            {categories ? categories.join(", ") : null}
          </p>
        </div>
      </Link>
    </div>
  )
}

export default Work
