import React, { useContext } from "react"

/* Import Local COntext */
import CarouselContext from "../../context/_carousel"

/* Import Global Components */
import DownArrowSvg from "~components/icon/normal/downArrow"

/* Import Local Styles */
import "./next-work-button.css"

const NextWorkButton = () => {
  const [, { nextSlide }] = useContext(CarouselContext)

  return (
    <div className="next-work-button">
      <button onClick={nextSlide}>
        <DownArrowSvg />
      </button>
    </div>
  )
}

export default NextWorkButton
