import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Children,
  isValidElement,
  cloneElement,
} from "react"
import { useSwipeable } from "react-swipeable"
import classnames from "classnames"

import CarouselContext from "../../context/_carousel"

import "./carousel.css"
import CarouselProvider from "../../providers/_carousel"

const Carousel = ({ children, loop = false }) => {
  const [
    { isInitialized },
    { initializeCarousel, nextSlide, previousSlide },
  ] = useContext(CarouselContext)
  const [clonedChildren, setClonedChildren] = useState([])
  const [clonedChildrenWithProps, setClonedChildrenWithProps] = useState([])
  const carouselRef = useRef(null)

  // swipe handlers for mobile
  const handlers = useSwipeable({
    onSwiped: ({ dir }) => {
      switch (dir) {
        case "Up":
          nextSlide()
          break
        case "Down":
          previousSlide()
          break
        default:
          break
      }
    },
  })

  // passthrough ref for sharing with swipe handlers
  // required by `react-swipeable` library to share ref
  const refPassthrough = el => {
    // call useSwipeable ref prop with el
    handlers.ref(el)
    // set myRef el so you can access it yourself
    carouselRef.current = el
  }

  // carousel needs at least 5 children, clone items in array if necessary
  useEffect(() => {
    if (children && loop) {
      // handle single child
      if (Children.count(children) === 1) {
        setClonedChildren([children, children, children, children, children])
        // handle less than 5 children
      } else if (Children.count(children) < 5) {
        let clonedChildren = []
        while (clonedChildren.length <= 5) {
          clonedChildren = clonedChildren.concat(children)
        }
        setClonedChildren(clonedChildren)
      } else {
        setClonedChildren(children)
      }
    } else if (children && !loop) {
      setClonedChildren(children)
    }
  }, [children, loop])

  // extend cloned children ( at least 5 ) with additional props
  useEffect(() => {
    if (clonedChildren) {
      setClonedChildrenWithProps(
        Children.map(clonedChildren, (child, index) => {
          // checking isValidElement is the safe way and avoids a typescript error too
          if (isValidElement(child)) {
            return cloneElement(child, { index: index })
          }
          return child
        })
      )
    }
  }, [clonedChildren])

  // once cloned children with necessary props created, initialize carousel
  useEffect(() => {
    if (clonedChildrenWithProps && clonedChildrenWithProps.length >= 1) {
      initializeCarousel(clonedChildrenWithProps.length - 1)
    }
  }, [clonedChildrenWithProps, initializeCarousel])

  return (
    <div
      className={classnames("carousel", { "is-initialized": isInitialized })}
      {...handlers}
      ref={refPassthrough}
    >
      {clonedChildrenWithProps}
    </div>
  )
}

export default Carousel
