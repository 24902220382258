import React, { useState } from "react"

// Import Local Context(s)
import CarouselContext from "../context/_carousel"

const CarouselProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [carouselLength, setCarouselLength] = useState(0)
  const [carouselPosition, setCarouselPosition] = useState(0)

  const initializeCarousel = length => {
    setCarouselLength(length)
    setIsInitialized(true)
  }

  const nextSlide = () => {
    if (carouselPosition === carouselLength) {
      setCarouselPosition(0)
    } else {
      setCarouselPosition(carouselPosition + 1)
    }
  }

  const previousSlide = () => {
    if (carouselPosition === 0) {
      setCarouselPosition(carouselLength)
    } else {
      setCarouselPosition(carouselPosition - 1)
    }
  }

  const state = { carouselLength, carouselPosition, isInitialized }
  const methods = { initializeCarousel, nextSlide, previousSlide }

  return (
    <CarouselContext.Provider value={[state, methods]}>
      {children}
    </CarouselContext.Provider>
  )
}

export default CarouselProvider
