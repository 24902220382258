import React, {
  useState,
  useEffect,
  useContext,
  Children,
  isValidElement,
  cloneElement,
} from "react"
import { useWindowHeight } from "@react-hook/window-size"
import classnames from "classnames"

import CarouselContext from "../../context/_carousel"

import "./carousel-item.css"

const CarouselItem = ({ children, index }) => {
  const windowHeight = useWindowHeight()
  const [{ carouselPosition }] = useContext(CarouselContext)
  const [clonedChildrenWithProps, setClonedChildrenWithProps] = useState([])
  const [yPosition, setYPosition] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [isNext, setIsNext] = useState(false)
  const [isPrevious, setIsPrevious] = useState(false)

  // set position of element based on current active carousel item
  useEffect(() => {
    const distance = carouselPosition - index
    const yPosition = ((distance * windowHeight) / 2) * -1
    setYPosition(yPosition)
  }, [index, windowHeight, carouselPosition])

  // set active based on current active carousel item
  useEffect(() => {
    setIsActive(index === carouselPosition)
    setIsPrevious(index === carouselPosition - 1)
    setIsNext(index === carouselPosition + 1)
  }, [index, carouselPosition])

  // extend cloned children ( at least 5 ) with props
  useEffect(() => {
    if (children) {
      setClonedChildrenWithProps(
        Children.map(children, (child, index) => {
          // checking isValidElement is the safe way and avoids a typescript error too
          if (isValidElement(child)) {
            return cloneElement(child, { active: isActive })
          }
          return child
        })
      )
    }
  }, [children, isActive])

  return (
    <div
      className={classnames("carousel-item", `carousel-item-index-${index}`, {
        [`carousel-item-active`]: isActive,
        [`carousel-item-previous`]: isPrevious,
        [`carousel-item-next`]: isNext,
      })}
      style={{ transform: `translateY(${yPosition}px)` }}
    >
      {clonedChildrenWithProps}
    </div>
  )
}

export default CarouselItem
